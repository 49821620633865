.App {
  text-align: center;
}

a {
  color: #01579b;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.Links a + a {
  margin-left: 0.8em;
}

.Links {
  position: relative;
  z-index: 1;
}

.Links:before {
  border-top: 2px solid #dfdfdf;
  content: '';
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: -10%;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 120%;
}

.Links a {
  background: #fff;
  padding: 0 0.4em;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.Title {
  text-align: left;
  flex-grow: 0;
}

.Title h1 {
  margin-bottom: 0;
}

.Subtitle {
  color: #555;
  margin-top: 0.5em;
  margin-bottom: 1.5em;
}
